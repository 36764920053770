import React from "react";

import { Link, redirect, useNavigate } from "react-router-dom";

import {
  useAuthenticator,
  Menu,
  MenuItem,
  Divider,
  Text,
  Card,
} from "@aws-amplify/ui-react";

import { useAppContext } from "./AppStore";
import dateToString from "../lib/dateToString";
import { I18n } from "aws-amplify";

const TopNav = () => {
  const navigate = useNavigate();
  const { currentUser, subscription, setSubscription, setViews } =
    useAppContext();
  const { signOut: signOutUserSession } = useAuthenticator((context) => [
    context.user,
    context.signOut,
  ]);

  const goTo = (link) => () => {
    navigate(link);
  };

  const signOut = () => {
    setSubscription(null);
    setViews(null);
    signOutUserSession();
    redirect("/");
  };

  if (!currentUser)
    return (
      <Link to="/login" style={{ lineHeight: "3rem" }}>
        {I18n.get("Sign In")}
      </Link>
    );

  return (
    <Menu size="small" menuAlign="end">
      {subscription?.views > 0 && (
        <Card
          style={{ paddingTop: "5px", paddingBottom: "5px", marginTop: "5px" }}
        >
          <Text>Доступно просмотров: {subscription.views}</Text>
        </Card>
      )}
      {subscription?.expiresAt &&
        subscription.expiresAt > new Date().toISOString() && (
          <Card style={{ paddingTop: "5px", paddingBottom: "5px" }}>
            <Text>
              Абонемент гден еще {dateToString(subscription.expiresAt)}
            </Text>
          </Card>
        )}
      {currentUser?.admin && (
        <>
          <Divider size="small" />
          <MenuItem onClick={goTo("/videos/create")}>Добавить видео</MenuItem>
          <MenuItem onClick={goTo("/dictionaries")}>Ярлыки</MenuItem>
          <MenuItem onClick={goTo("/users")}>Пользователи</MenuItem>
        </>
      )}
      <Divider size="small" />
      <MenuItem onClick={goTo("/videos")}>Мой видео</MenuItem>
      <Divider size="small" />
      <MenuItem onClick={signOut}>{I18n.get("Sign Out")}</MenuItem>
    </Menu>
  );
};

export default TopNav;
