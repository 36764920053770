import React from "react";

import { useNavigate } from "react-router";

import { View, Heading, Divider } from "@aws-amplify/ui-react";
import { createVideo } from "../../api/admin";
import VideoForm from "../../components/VideoForm";

const CreateVideo = () => {
  const navigate = useNavigate();

  const handleCreate = async (input) => {
    const response = await createVideo(input);

    navigate(`/videos/${response.data.id}/update`);
  };

  return (
    <View>
      <Heading level={"1"}>Добавить видео</Heading>
      <Divider marginTop="20px" marginBottom="20px" />
      <VideoForm onSubmit={handleCreate} />
    </View>
  );
};

export default CreateVideo;
