import React, { useState } from "react";

import {
  View,
  Alert,
  Flex,
  TextField,
  TextAreaField,
  Button,
  Loader,
  Text,
  Card,
  CheckboxField,
  Divider,
} from "@aws-amplify/ui-react";
import { useAppContext } from "./AppStore";

function validateInput(data) {
  const errors = {};

  if (!data.title) {
    errors.title = "Title is required";
  }

  if (!data.description) {
    errors.description = "Description is required";
  }

  if (!data.vimeoUrl) {
    errors.vimeoUrl = "Description is required";
  }

  if (data.dictionaries.length === 0) {
    errors.dictionaries = "You need to check at least one tag";
  }

  errors.hasErrors = Object.keys(errors).length > 0;

  return errors;
}

const defaults = {
  title: "",
  description: "",
  vimeoUrl: "",
  dictionaries: [],
};

const VideoForm = ({ initalState, onClear, onSubmit }) => {
  const [input, setInput] = useState(initalState || defaults);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(null);
  const [alerts, setAlerts] = useState([]);
  const { dictionaries } = useAppContext();

  const validate = () => {
    const res = validateInput(input);

    setErrors(res);

    return res;
  };

  const handleFormInput = (e) => {
    setInput({ ...input, [e.currentTarget.name]: e.currentTarget.value });
  };

  const handleClear = async () => {
    if (onClear) await onClear();
    setInput(defaults);
    setErrors({});
  };

  const handleChecked = (dictionary, value) => {
    if (value) {
      if (!input.dictionaries.includes(dictionary.id)) {
        setInput({
          ...input,
          dictionaries: [...input.dictionaries, dictionary.id],
        });
      }
    } else {
      if (input.dictionaries.includes(dictionary.id)) {
        setInput({
          ...input,
          dictionaries: input.dictionaries.filter((v) => v !== dictionary.id),
        });
      }
    }
  };

  const handleSubmit = async () => {
    if (validate().hasErrors) return;

    try {
      if (onSubmit) await onSubmit(input);

      setAlerts([...alerts, "Данные сохранены!"]);
      setInput(defaults);
      setError(null);
    } catch (error) {
      const data = error.response?.data;
      if (data.errorName === "ValidationError") {
        setError({title: "Ошибки", messages: Object.values(data.errors)});
      } else {
        setError({title: "Ошибка сервера", messages: [error.message]});
      }
    }
  };

  return (
    <Flex direction={"column"}>
      {error && (
        <View>
          <Alert
            variation="error"
            hasIcon={true}
            heading={error.title}
            isDismissible={false}
          >
            <ul>
              {error.messages.map((message) => (
                <li>
                  {message}
                </li>
              ))}
            </ul>
          </Alert>
        </View>
      )}
      {alerts.map((alert, index) => (
        <Alert
          key={index.toString()}
          isDismissible={true}
          hasIcon={true}
          variatio="info"
        >
          {alert}
        </Alert>
      ))}
      <TextField
        label={"Заголовок:"}
        name={"title"}
        value={input.title}
        onChange={handleFormInput}
        hasError={errors.title}
        errorMessage={"это обязательное поле"}
        placeholder="Заголовок"
      />
      <TextAreaField
        label={"Описание:"}
        name={"description"}
        value={input.description}
        onChange={handleFormInput}
        hasError={errors.description}
        errorMessage={"это обязательное поле"}
        placeholder="Описание"
      />
      <TextField
        label={"Vimeo ссылка:"}
        name={"vimeoUrl"}
        value={input.vimeoUrl}
        onChange={handleFormInput}
        hasError={errors.vimeoUrl}
        errorMessage={"это обязательное поле"}
        placeholder="https://player.vimeo.com/video/701143413"
      />
      <Divider marginTop="20px" marginBottom="20px" />
      {dictionaries ? (
        Object.entries(dictionaries).map((entry) => {
          const [key, value] = entry;
          return (
            <Card key={key} padding="0px">
              <Text>{key} :</Text>
              <View paddingLeft="25px">
                {value.map((dictionary) => {
                  return (
                    <CheckboxField
                      key={dictionary.id}
                      label={dictionary.value}
                      name={dictionary.valueSlug}
                      value={dictionary.id}
                      checked={input.dictionaries.includes(dictionary.id)}
                      hasError={errors.dictionaries}
                      errorMessage="Как минимум один ярлык должен быть выбран"
                      onChange={(e) => {
                        handleChecked(dictionary, e.target.checked);
                      }}
                    />
                  );
                })}
              </View>
            </Card>
          );
        })
      ) : (
        <Loader
          size="small"
          variation="linear"
          ariaLabel="Загрузка данных о ярлыках ..."
        />
      )}

      <Flex direction={"row"} justifyContent={"right"}>
        <Button variation="primary" onClick={handleSubmit}>
          Сохранить
        </Button>
        <Button variation="destructive" onClick={handleClear}>
          Отменить
        </Button>
      </Flex>
    </Flex>
  );
};

export default VideoForm;
