import { API } from "aws-amplify";

const API_NAME = "PublicApi";

const callEndpoint = async (method, endpoint, params = {}) => {
  try {
    return await API[method](API_NAME, endpoint, params);
  } catch (error) {
    console.info(error);

    throw error;
  }
};

const getVideo = async (id) => {
  return await callEndpoint("get", `/videos/${id}`);
};

const listVideos = async () => {
  return await callEndpoint("get", "/videos");
};

const searchVideos = async (data) => {
  return await callEndpoint("post", "/videos/search", { body: { data } });
};

const listDictionaries = async () => {
  return await callEndpoint("get", "/dictionaries");
};

export { listVideos, listDictionaries, searchVideos, getVideo };
