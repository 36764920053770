export default function dateToString(input) {
  let date = input;
  if (Object.prototype.toString.call(input) !== "[object Date]") {
    date = Date.parse(input);
  }
  var seconds = Math.round(Math.abs(date - new Date()) / 1000);
  var years = Math.round(seconds / 31536000);
  var days = Math.round(seconds / 86400);

  if (years > 4) return years + " лет";
  if (days > 548) return years + " года";
  if (days >= 320) return "год";

  var months = Math.round(seconds / 2592000);

  if (months > 4) return months + " месяцев";
  if (days >= 45) return months + " месяца";
  if (days >= 26) return "месяц";

  var hours = Math.round(seconds / 3600);

  if (days > 4) return days + " дней";
  if (hours >= 36) return days + " дня";
  if (hours >= 22) return "день";

  var minutes = Math.round(seconds / 60);

  if (hours > 4) return hours + " часов";
  if (minutes >= 90) return hours + " часа";
  if (minutes >= 45) return "час";

  if (minutes > 4) return minutes + " минут";
  if (seconds >= 90) return minutes + " минуты";
  if (seconds >= 45) return "минуту";

  if (seconds >= 0) return "несколько секунд";
}
