import React from "react";

import { Link, NavLink, Outlet } from "react-router-dom";
import { Grid, Card, Flex, View, Image } from "@aws-amplify/ui-react";

import TopNav from "./TopNav";

const Layout = () => {
  return (
    <Grid
      columnGap="0"
      rowGap="0"
      templateColumns="auto"
      templateRows="110px auto"
      height="100vh"
    >
      <Card
        rowStart="1"
        rowEnd="2"
        columnStart="1"
        columnEnd="-1"
        backgroundColor="#ED713D"
        padding="0px"
      >
        <Flex direction="row" justifyContent="right" alignContent="center">
          <View style={{ flexGrow: "1" }}>
            <NavLink
              to="/"
              component={Link}
              style={{ display: "block", width: "311px", height: "103px" }}
            >
              <Image src="/images/logo.png" />
            </NavLink>
          </View>
          <View>
            <TopNav />
          </View>
        </Flex>
      </Card>
      <Card rowStart="2" rowEnd="-1" borderRadius="10px">
        <Outlet />
      </Card>
    </Grid>
  );
};

export default Layout;
