import React, { useEffect, useState } from "react";

import { View, Heading, Alert, Loader, Divider } from "@aws-amplify/ui-react";
import { updateVideo, getVideo } from "../../api/admin";
import VideoForm from "../../components/VideoForm";
import { useNavigate, useParams } from "react-router-dom";

const UpdateVideo = () => {
  const { id } = useParams();

  const [video, setVideo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchVideo() {
      try {
        const response = await getVideo(id);
        if (response.data) {
          setVideo(response.data);
        } else {
          setError({
            heading: "Данных о видео не обнаружено!",
            message:
              "Не удалось загрузить данные о видео. Зайдите на главную страницу, чтобы просмотреть доступные видео.",
          });
        }

        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }
    fetchVideo();
  }, [id]);

  const handleUpdate = async (input) => {
    await updateVideo(input);

    navigate("/");
  };

  if (error) {
    return (
      <View>
        <Alert
          variation="error"
          hasIcon={true}
          heading={error.heading || "Ошибка сервера"}
          isDismissible={false}
        >
          {error.message}
        </Alert>
      </View>
    );
  }

  if (loading) {
    return (
      <View>
        <Loader
          size="small"
          variation="linear"
          ariaLabel="Данные о видео загружается  ..."
        />
      </View>
    );
  }

  return (
    <View>
      <Heading level={"1"}>Изменить видео</Heading>
      <Divider marginTop="20px" marginBottom="20px" />
      {video && <VideoForm initalState={video} onSubmit={handleUpdate} />}
    </View>
  );
};

export default UpdateVideo;
