import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Divider,
  Flex,
  Heading,
  Loader,
  Text,
  View,
} from "@aws-amplify/ui-react";
import { useAppContext } from "../components/AppStore";
import Filters from "../components/NavBar/Filters";
import { searchVideos } from "../api/public";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const { currentUser, filters } = useAppContext();
  const [videos, setVideos] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (filters === null) return;
    const fetchData = async () => {
      setLoading(true);
      const { data } = await searchVideos({ filters });

      setVideos(
        data.sort(function (a, b) {
          return a.title.localeCompare(b.title);
        })
      );

      setLoading(false);
    };

    fetchData();
  }, [filters]);

  const handleWatch = (id) => {
    navigate(`/watch/${id}`);
  };

  const handleUpdate = (id) => {
    navigate(`/videos/${id}/update`);
  };

  return (
    <Flex direction={{ base: "column", medium: "row" }}>
      <View>
        <Filters />
      </View>
      <View style={{ flexGrow: "1" }}>
        <Card gap={"2rem"}>
          {!loading && (videos === null || videos.length === 0) && (
            <Alert variation="info">Выберите категорию</Alert>
          )}
          {loading && (
            <Loader
              size="small"
              variation="linear"
              ariaLabel="Загрузка видео ..."
            />
          )}
          {videos &&
            videos.map((video) => (
              <Flex key={video.id} direction={"column"}>
                <Heading level={"5"}>{video.title}</Heading>
                <Divider />
                <Text as="span">{video.description}</Text>
                <Text>
                  {Object.entries(
                    (video.tags || []).reduce((res, tag) => {
                      res[tag.name] = [...(res[tag.name] || []), tag.value];
                      return res;
                    }, {})
                  ).map((entry) => {
                    const [key, value] = entry;

                    return key + ": " + value.join(", ") + "; ";
                  })}
                </Text>
                <Flex direction={"row"} justifyContent={"right"}>
                  {currentUser?.admin && (
                    <Button
                      variation="primary"
                      onClick={() => handleUpdate(video.id)}
                    >
                      Изменить
                    </Button>
                  )}
                  <Button
                    variation="primary"
                    onClick={() => handleWatch(video.id)}
                  >
                    Просмотр
                  </Button>
                </Flex>
              </Flex>
            ))}
        </Card>
      </View>
    </Flex>
  );
};

export default Home;
