import React, { useState } from "react";
import {
  Button,
  TextField,
  View,
  Loader,
  Card,
  Flex,
  Grid,
  Text,
  Divider,
  Heading,
} from "@aws-amplify/ui-react";

import { createDictionary, removeDictionary } from "../api/admin";
import { useAppContext } from "../components/AppStore";

const initialState = { name: "", value: "" };

const DictionaryRow = ({ dictionary, onDestroy }) => {
  return (
    <>
      <View columnStart={{ base: 1 }} columnEnd={{ base: 2 }}>
        <Text as="p">
          <Text as="span" fontWeight="700">
            {dictionary.name}
          </Text>
          : {dictionary.value}
        </Text>
      </View>
      <View
        columnStart={{ base: 1, small: 2 }}
        columnEnd={{ base: 2, small: 3 }}
      >
        <Flex justifyContent="end">
          <Button
            variation="destructive"
            size="small"
            onClick={() => onDestroy(dictionary)}
          >
            Удалить
          </Button>
        </Flex>
      </View>
      <Divider size="small" columnStart={1} columnEnd={-1} />
    </>
  );
};

const Dictionaries = () => {
  const { dictionaries, setDictionaries } = useAppContext();
  const [formState, setFormState] = useState(initialState);

  const setInput = (key, value) => {
    setFormState({ ...formState, [key]: value });
  };

  const handleRemoveDictionary = async ({ id, name }) => {
    try {
      await removeDictionary({ id });
      setDictionaries({
        ...dictionaries,
        [name]: dictionaries[name].filter((dictionary) => dictionary.id !== id),
      });
    } catch (err) {
      console.log("error removing dictionary:", err);
    }
  };

  const handleCreateDictionary = async () => {
    try {
      const { data } = await createDictionary(formState);
      setDictionaries({
        ...dictionaries,
        [data.name]: [...(dictionaries[data.name] || []), data],
      });
      setFormState(initialState);
    } catch (err) {
      console.log("error creating dictionary:", err);
    }
  };

  return (
    <View>
      <Heading level={"2"}>Ярлыки</Heading>
      <Divider marginTop="20px" marginBottom="20px" />
      <Card>
        <Flex
          direction={{ base: "column", large: "row" }}
          alignItems={{ large: "end" }}
        >
          <TextField
            placeholder="Уровень"
            label="Имя ярлыка:"
            errorMessage="Это обязательное поле"
            onChange={(event) => setInput("name", event.currentTarget.value)}
            value={formState.name}
            isRequired={true}
            labelHidden={false}
          />
          <TextField
            placeholder="Продвинутые"
            label="Значение ярлыка:"
            errorMessage="Это обязательное поле"
            onChange={(event) => setInput("value", event.currentTarget.value)}
            value={formState.value}
            isRequired={true}
            labelHidden={false}
          />
          <Flex justifyContent="end">
            <Button
              variation="primary"
              onClick={handleCreateDictionary}
              style={{ whiteSpace: "nowrap" }}
            >
              Добавить ярлык
            </Button>
          </Flex>
        </Flex>
      </Card>
      <Card>
        {dictionaries ? (
          <Grid
            templateColumns={{ base: "1fr", small: "1fr 1fr" }}
            columnGap="0.5rem"
            rowGap="0.5rem"
          >
            {Object.values(dictionaries)
              .flat()
              .map((dictionary) => (
                <DictionaryRow
                  key={dictionary.id}
                  dictionary={dictionary}
                  onDestroy={handleRemoveDictionary}
                />
              ))}
          </Grid>
        ) : (
          <Loader
            size="small"
            variation="linear"
            ariaLabel="Loading dictionaries ..."
          />
        )}
      </Card>
    </View>
  );
};

export default Dictionaries;
