import { API, Auth } from "aws-amplify";

const RENTALS_ADMIN_API = "AdminApi";
const ADMIN_QUERIES_API = "AdminQueries";

const callEndpoint = async (api, method, endpoint, params = {}) => {
  try {
    return await API[method](api, endpoint, params);
  } catch (error) {
    console.info(error);

    throw error;
  }
};

const callRentalsAdminApi = async (method, endpoint, params = {}) => {
  return await callEndpoint(RENTALS_ADMIN_API, method, endpoint, params);
};

const callAdminQueriesApi = async (method, endpoint, params = {}) => {
  return await callEndpoint(ADMIN_QUERIES_API, method, endpoint, params);
};

const getVideo = async (id) => {
  return await callRentalsAdminApi("get", `/videos/${id}`);
};

const createVideo = async (data) => {
  return await callRentalsAdminApi("post", "/videos", {
    body: { data },
  });
};

const updateVideo = async (data) => {
  return await callRentalsAdminApi("put", "/videos/" + data.id, {
    body: { data },
  });
};

const removeVideo = async ({ id }) => {
  return await callRentalsAdminApi("del", "/videos/" + id);
};

const listSubscriptions = async () => {
  return await callRentalsAdminApi("get", "/subscriptions");
};

const createSubscription = async (data) => {
  return await callRentalsAdminApi("post", "/subscriptions", {
    body: { data },
  });
};

const updateSubscription = async (data) => {
  return await callRentalsAdminApi("put", "/subscriptions", {
    body: { data },
  });
};

const removeSubscription = async ({ id }) => {
  return await callRentalsAdminApi("del", "/subscriptions/" + id);
};

const listUsers = async () => {
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return await callAdminQueriesApi("get", "/listUsers", params);
};

const createDictionary = async (data) => {
  return await callRentalsAdminApi("post", "/dictionaries", {
    body: { data },
  });
};

const removeDictionary = async ({ id }) => {
  return await callRentalsAdminApi("del", "/dictionaries/" + id);
};

export {
  createDictionary,
  removeDictionary,
  getVideo,
  createVideo,
  updateVideo,
  removeVideo,
  listSubscriptions,
  createSubscription,
  updateSubscription,
  removeSubscription,
  listUsers,
};
