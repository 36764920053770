import React, { useMemo } from "react";

import { Loader } from "@aws-amplify/ui-react";

import { useAppContext } from "../AppStore";
import Filter from "./Filter";

const Filters = () => {
  const { dictionaries } = useAppContext();

  const dictionariesWithItems = useMemo(() => {
    if (dictionaries === null) return;

    return Object.entries(dictionaries).reduce((res, [name, values]) => {
      const tags = values.filter((item) => +item.count > 0);
      if (tags.length !== 0) {
        res[name] = tags;
      }

      return res;
    }, {});
  }, [dictionaries]);

  if (dictionaries === null) {
    return (
      <Loader
        size="small"
        variation="linear"
        ariaLabel="Loading dictionaries ..."
      />
    );
  }

  return Object.entries(dictionariesWithItems).map(([name, values]) => (
    <Filter key={name} name={name} values={values} />
  ));
};

export default Filters;
