import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Divider,
  Flex,
  Heading,
  Loader,
  Text,
  View,
} from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { listViews } from "../api/private";
import { useAppContext } from "../components/AppStore";
import { searchVideos } from "../api/public";
import dateToString from "../lib/dateToString";

const canWatchVideo = ({ video, views }) => {
  if (
    views &&
    views.find(
      (view) =>
        video.id === view.videoId && new Date() < Date.parse(view.expiresAt)
    )
  )
    return true;

  return false;
};

const Home = () => {
  const { currentUser } = useAppContext();
  const [views, setViews] = useState(null);
  const [videos, setVideos] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) return;

    async function fetchData() {
      const { data } = await listViews({
        userId: currentUser.id,
        expired: true,
      });
      setViews(
        data.sort(function (a, b) {
          return new Date(b.expiresAt) - new Date(a.expiresAt);
        })
      );
    }

    fetchData();
  }, [currentUser]);

  useEffect(() => {
    if (!views) return;

    async function fetchData() {
      const { data } = await searchVideos({
        videoIds: views.map((view) => view.videoId),
      });
      setVideos(data);
      setLoading(false);
    }

    fetchData();
  }, [views]);
  const handleWatch = (id) => {
    navigate(`/watch/${id}`);
  };

  return (
    <View style={{ flexGrow: "1" }}>
      <Heading level={3}>Ваши видео</Heading>
      <Divider marginTop="20px" marginBottom="20px" />
      <Card gap={"2rem"}>
        {loading && (
          <Loader
            size="small"
            variation="linear"
            ariaLabel="Загрузка видео ..."
          />
        )}
        {videos &&
          videos.map((video) => (
            <Flex key={video.id} direction={"column"}>
              <Heading level={"5"}>{video.title}</Heading>
              <Divider />
              <Text as="span">{video.description}</Text>
              <Text>
                {Object.entries(
                  (video.tags || []).reduce((res, tag) => {
                    res[tag.name] = [...(res[tag.name] || []), tag.value];
                    return res;
                  }, {})
                ).map((entry) => {
                  const [key, value] = entry;

                  return key + ": " + value.join(", ") + "; ";
                })}
              </Text>
              <Flex direction={"row"} justifyContent={"right"}>
                {canWatchVideo({ video, views }) ? (
                  <Button
                    variation="primary"
                    onClick={() => handleWatch(video.id)}
                  >
                    Просмотр
                  </Button>
                ) : (
                  <Text>
                    Срок действия подписки истек{" "}
                    {dateToString(
                      views.find((view) => video.id === view.videoId)?.expiresAt
                    )}{" "}
                    назад
                  </Text>
                )}
              </Flex>
            </Flex>
          ))}
      </Card>
    </View>
  );
};

export default Home;
