import React from "react";

import { Text, View, Card, CheckboxField } from "@aws-amplify/ui-react";

import { useAppContext } from "../AppStore";

const Filter = ({ name, values }) => {
  const { filters, setFilters } = useAppContext();

  const handleChecked = (id, value) => {
    if (filters?.includes(id)) {
      setFilters(filters.filter((v) => v !== id));
    } else if (value) {
      setFilters([...filters, id]);
    }
  };

  return (
    <Card padding="5px">
      <Text>{name} :</Text>
      <View paddingLeft="25px">
        {values.map((dictionary) => (
          <CheckboxField
            key={dictionary.id}
            label={dictionary.value + " (" + dictionary.count + ")"}
            name={dictionary.valueSlug}
            value={dictionary.id}
            checked={filters?.includes(dictionary.id)}
            onChange={(e) => {
              handleChecked(dictionary.id, e.target.checked);
            }}
          />
        ))}
      </View>
    </Card>
  );
};

export default Filter;
