import { useAuthenticator } from "@aws-amplify/ui-react";
import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from "react";
import { getSubscription, listViews } from "../api/private";
import { listDictionaries } from "../api/public";

const AppContext = createContext(undefined);

// Hook to provide access to context object
const useAppContext = () => {
  return useContext(AppContext);
};

const Provider = (props) => {
  const { user } = useAuthenticator((context) => [context.user]);

  const currentUser = useMemo(() => {
    if (!user?.signInUserSession) return;

    return {
      id: user.username,
      admin:
        !!user.signInUserSession.accessToken.payload[
          "cognito:groups"
        ]?.includes("admin"),
    };
  }, [user]);

  const [subscription, setSubscription] = useState(null);
  const [dictionaries, setDictionaries] = useState(null);
  const [views, setViews] = useState(null);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await listDictionaries();
      const normalized = data.reduce((res, item) => {
        res[item.name] = [...(res[item.name] || []), item];

        return res;
      }, {});

      setDictionaries(normalized);
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (!currentUser) return;

    async function fetchData() {
      const { data } = await listViews({
        userId: currentUser.id,
        expired: false,
      });
      setViews(data);
    }

    fetchData();
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser) return;

    async function fetchData() {
      const { data } = await getSubscription(currentUser.id);
      setSubscription(data);
    }

    fetchData();
  }, [currentUser]);

  const AppObject = useMemo(
    () => ({
      filters,
      setFilters,
      dictionaries,
      setDictionaries,
      currentUser,
      subscription,
      setSubscription,
      views,
      setViews,
    }),
    [currentUser, subscription, views, dictionaries, filters]
  );

  return (
    <AppContext.Provider value={AppObject}>
      {props.children}
    </AppContext.Provider>
  );
};

export { Provider, useAppContext };
