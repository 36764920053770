import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Amplify, I18n } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import * as AppStore from "./components/AppStore";

import Layout from "./components/Layout";

import Home from "./pages/Home";
import Videos from "./pages/Videos";
import Dictionaries from "./pages/Dictionaries";
import Users from "./pages/Users";
import Watch from "./pages/Watch";
import Login from "./pages/Login";
import VideosCreate from "./pages/videos/Create";
import VideosUpdate from "./pages/videos/Update";

import "@aws-amplify/ui-react/styles.css";
import "./index.css";

import reportWebVitals from "./reportWebVitals";
import awsExports from "./aws-exports";
import RequireAdmin from "./components/Routes/RequireAdmin";
import { RequireAuth } from "./components/Routes/RequireAuth";

import translations from "./translations";

I18n.setLanguage("ru");
I18n.putVocabulariesForLanguage("ru", translations);

Amplify.configure(awsExports);

export default function App() {
  return (
    <Authenticator.Provider>
      <AppStore.Provider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/watch/:id" element={<Watch />} />

              <Route element={<RequireAuth />}>
                <Route path="/videos" element={<Videos />} />
                <Route element={<RequireAdmin />}>
                  <Route path="/dictionaries" element={<Dictionaries />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/videos/create" element={<VideosCreate />} />
                  <Route path="/videos/:id/update" element={<VideosUpdate />} />
                </Route>
              </Route>

              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AppStore.Provider>
    </Authenticator.Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
