import { Navigate, Outlet } from "react-router-dom";
import { useAppContext } from "../AppStore";

const RequireAdmin = () => {
  const { currentUser } = useAppContext();

  if (currentUser?.admin) {
    return <Outlet />;
  } else {
    return <Navigate to="/" />;
  }
};

export default RequireAdmin;
