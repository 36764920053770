const translations = {
  "Account recovery requires verified contact information":
    "Восстановление учетной записи требует проверки контактной информации",
  "Back to Sign In": "Назад, чтобы войти",
  "Change Password": "Изменить пароль",
  Changing: "Изменение",
  Code: "Код",
  "Code *": "Код *",
  "Confirm Password": "Подтвердите пароль",
  "Please confirm your Password": "Введите пароль еще раз",
  "Confirm Sign Up": "Подтверждение регистрации",
  "Confirm SMS Code": "Подтверждение CMC-Код",
  "Confirm TOTP Code": "Подтверждение TOTP-Код",
  Confirm: "Подтвердите",
  "Confirmation Code": "Код подтверждения",
  Confirming: "Подтверждаем",
  "Create a new account": "Создавать новую учетную запись",
  "Create Account": "Создать учетную запись",
  "Creating Account": "Учетная запись создается ",
  "Dismiss alert": "Закрыть оповещение",
  Name: "Имя",
  Password: "Пароль",
  Email: "Электронная почта",
  Username: "Имя пользователя",
  "Enter your code": "Введите ваш Код",
  "Enter your Password": "Введите пароль",
  "Enter your Name": "Введите имя",
  "Enter your email": "Введите вашу электронную почту",
  "Enter your Email": "Введите вашу электронную почту",
  "Enter your phone number": "Введите ваш номер телефона",
  "Enter your username": "Введите ваше имя пользователя",
  "Forgot your password?": "Забыли ваш пароль?",
  "Hide password": "Скрыть пароль",
  "It may take a minute to arrive": "Доставка может занять некоторое время",
  Loading: "Загрузка",
  "New password": "Новый пароль",
  "New Password": "Новый пароль",
  or: "или",
  "Phone Number": "Номер телефона",
  "Resend Code": "Отправь Код еще раз",
  "Reset your password": "Сбросить ваш пароль",
  "Reset your Password": "Сбросить ваш пароль",
  "Reset Password": "Сбросить ваш пароль",
  "Send code": "Отправить Код",
  "Send Code": "Отправить Код",
  Sending: "отправка",
  "Setup TOTP": "Настраивать TOTP",
  "Show password": "Показать пароль",
  "Sign in to your account": "Войти в свой аккаунт",
  "Sign In with Amazon": "Войти через Amazon",
  "Sign In with Apple": "Войти через Apple",
  "Sign In with Facebook": "Войти через Facebook",
  "Sign In with Google": "Войти через Google",
  "Sign out": "Выйти",
  "Sign Out": "Выйти",
  "Sign in": "Войти",
  "Sign In": "Войти",
  "Signing in": "Вход в",
  Skip: "Пропустить",
  Submit: "Отправить",
  Submitting: "Отправляется",
  "Verify Contact": "Подтвердить контакт",
  Verify: "Проверить",
  "We Emailed You": "Мы отправили вам электронное письмо",
  "We Sent A Code": "Мы отправили код",
  "We Texted You": "Мы отправили вам текстовое сообщение",
  "Your code is on the way. To log in, enter the code we emailed to":
    "Ваш код отправлен. Чтобы войти в систему, введите код, который мы отправили по электронной почте",
  "Your code is on the way. To log in, enter the code we sent you":
    "Ваш код отправлен. Чтобы войти в систему, введите код, который мы послали вам",
  "Your code is on the way. To log in, enter the code we texted to":
    "Ваш код отправлен. Чтобы войти в систему, введите код, который мы отправили текстовым сообщением",
  "Password must have at least 8 characters":
    "Пароль должен быть длиннее чем 8 символов",
  "Your passwords must match": "Пароли должны совподать",
  "Invalid verification code provided, please try again.":
    "Код введен не верно, попробуйте еще раз.",
  "Attempt limit exceeded, please try after some time.":
    "Лимит попыток исчерпан, попробуйте еще раз через какое-то время.",
  "Username cannot be empty": "Введите адрес электронной почты",
  "Password cannot be empty": "Введите пароль",
  "An account with the given email already exists.":
    "Пользователь с таким адресом электронной почты уже существует",
};

export default translations;
