import {
  Alert,
  Button,
  Card,
  Heading,
  Loader,
  View,
  Text,
  Flex,
  StepperField,
  Grid,
  Divider,
} from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import {
  createSubscription,
  updateSubscription,
  listSubscriptions,
  listUsers,
} from "../api/admin";
import { useAppContext } from "../components/AppStore";
import dateToString from "../lib/dateToString";

const isUserActive = (user) => {
  return (
    user.Attributes.find((attr) => attr.Name === "email_verified")?.Value ===
      "true" &&
    user.Enabled &&
    user.UserStatus === "CONFIRMED"
  );
};

const expiresAtDateString = (subscription) => {
  const dateString =
    Date.parse(subscription?.expiresAt) > new Date()
      ? dateToString(subscription.expiresAt)
      : "0 дней";

  return "Абонемент на " + dateString;
};

const UserRow = ({ user, subscription, onAddDays, onAddViews }) => (
  <>
    <View
      columnStart={{ base: 1, medium: 1, large: 1 }}
      columnEnd={{ base: 2, medium: 2, large: 3 }}
    >
      <Flex
        direction={{ base: "column", large: "row" }}
        alignContent="start"
        justifyContent="start"
      >
        <Heading level={5}>
          {user.Attributes.find((attr) => attr.Name === "name").Value}
        </Heading>
        <Text>
          {user.Attributes.find((attr) => attr.Name === "email").Value}
        </Text>
      </Flex>
    </View>
    <View
      columnStart={{ base: 1, medium: 2, large: 3 }}
      columnEnd={{ base: 2, medium: 3, large: 4 }}
    >
      <Flex direction={"row"} alignContent="end" justifyContent="end">
        <Text lineHeight={"2rem"}>{subscription?.views || 0} views</Text>
        <Button
          size="small"
          variation="primary"
          onClick={() => {
            onAddViews(user);
          }}
        >
          Добавить
        </Button>
      </Flex>
    </View>
    <View
      columnStart={{ base: 1, medium: 3, large: 4 }}
      columnEnd={{ base: 2, medium: 4, large: 5 }}
    >
      <Flex direction={"row"} alignContent="end" justifyContent="end">
        <Text lineHeight={"2rem"}>{expiresAtDateString(subscription)}</Text>
        <Button
          size="small"
          variation="primary"
          onClick={() => {
            onAddDays(user);
          }}
        >
          Добавить
        </Button>
      </Flex>
    </View>
    <Divider size="small" columnStart={1} columnEnd={-1} />
  </>
);

const Users = () => {
  const { currentUser, setSubscription: setCurrentUserSubscription } =
    useAppContext();

  const [usersCall, setUsersCall] = useState({ loading: true, error: null });
  const [subscriptionsCall, setSubscriptionsCall] = useState({
    loading: true,
    error: null,
  });

  const [users, setUsers] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);

  const [addDays, setAddDays] = useState({ isVisible: false });
  const [addViews, setAddViews] = useState({ isVisible: false });

  const handleAddDays = (user) => {
    setAddDays({ user, isVisible: true });
  };

  const handleAddViews = (user) => {
    setAddViews({ user, isVisible: true });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await listSubscriptions();

        setSubscriptionsCall({ loading: false, error: null });
        setSubscriptions(data.data);
      } catch (error) {
        setSubscriptionsCall({ loading: false, error });
        setSubscriptions(null);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await listUsers();

        setUsersCall({ loading: false, error: null });
        setUsers(data.Users.filter(isUserActive));
      } catch (error) {
        setUsersCall({ loading: false, error });
        setUsers(null);
      }
    }

    fetchData();
  }, []);

  if (addDays.isVisible) {
    const AddDays = ({ subscription }) => {
      const [value, setValue] = useState(27);

      const handleOnStepChange = (newValue) => {
        setValue(newValue);
      };

      const handleAdd = () => {
        if (subscription) {
          let expiresAt = subscription.expiresAt
            ? new Date(subscription.expiresAt)
            : new Date();
          if (expiresAt < new Date()) expiresAt = new Date();

          expiresAt.setDate(expiresAt.getDate() + +value);

          subscription.expiresAt = expiresAt.toISOString();

          updateSubscription(subscription).then(({ data }) => {
            if (data.userId === currentUser.id) {
              setCurrentUserSubscription(data);
            }
            setSubscriptions(
              subscriptions.map((item) => (item.id === data.id ? data : item))
            );
            setAddDays({ isVisible: false });
          });
        } else {
          const expiresAt = new Date();
          expiresAt.setDate(expiresAt.getDate() + +value);

          createSubscription({
            userId: addDays.user.Username,
            views: 0,
            expiresAt: expiresAt.toISOString(),
          }).then(({ data }) => {
            if (data.userId === currentUser.id) {
              setCurrentUserSubscription(data);
            }
            setSubscriptions([...subscriptions, data]);
            setAddDays({ isVisible: false });
          });
        }
      };

      return (
        <View>
          <Card>
            <Flex direction="column">
              <Heading level={3}>Абонемент</Heading>
              <Divider marginTop="20px" marginBottom="20px" />
              <StepperField
                size="large"
                variation="quiet"
                step={7}
                min={1}
                value={value}
                onStepChange={handleOnStepChange}
                label="Введите количество дней:"
              />
              <Button variation="primary" onClick={handleAdd}>
                Добавить
              </Button>
              <Button
                variation="warning"
                onClick={() => setAddDays({ isVisible: false })}
              >
                Отметить
              </Button>
            </Flex>
          </Card>
        </View>
      );
    };

    const subscription = subscriptions?.find(
      (subscription) => addDays.user.Username === subscription.userId
    );

    return <AddDays subscription={subscription} />;
  }

  if (addViews.isVisible) {
    const AddViews = ({ subscription }) => {
      const [value, setValue] = useState(1);

      const handleOnStepChange = (newValue) => {
        setValue(newValue);
      };

      const handleAdd = () => {
        if (subscription) {
          subscription.views = Math.max(+subscription.views, 0) + +value;
          updateSubscription(subscription).then(({ data }) => {
            if (data.userId === currentUser.id) {
              setCurrentUserSubscription(data);
            }
            setSubscriptions(
              subscriptions.map((item) => (item.id === data.id ? data : item))
            );
            setAddViews({ isVisible: false });
          });
        } else {
          createSubscription({
            userId: addViews.user.Username,
            views: +value,
            expiresAt: "",
          }).then(({ data }) => {
            if (data.userId === currentUser.id) {
              setCurrentUserSubscription(data);
            }
            setSubscriptions([...subscriptions, data]);
            setAddViews({ isVisible: false });
          });
        }
      };

      return (
        <View>
          <Card>
            <Flex direction="column">
              <Heading level={3}>Просмотры</Heading>
              <Divider marginTop="20px" marginBottom="20px" />
              <StepperField
                size="large"
                variation="quiet"
                step={1}
                min={1}
                value={value}
                onStepChange={handleOnStepChange}
                label="Введите количество просмотров:"
              />
              <Button variation="primary" onClick={handleAdd}>
                Добавить
              </Button>
              <Button
                variation="warning"
                onClick={() => setAddViews({ isVisible: false })}
              >
                Отметить
              </Button>
            </Flex>
          </Card>
        </View>
      );
    };

    const subscription = subscriptions?.find(
      (subscription) => addViews.user.Username === subscription.userId
    );

    return <AddViews subscription={subscription} />;
  }

  if (usersCall.error || subscriptionsCall.error) {
    return (
      <View>
        <Alert
          variation="error"
          hasIcon={true}
          heading="Ошибка сервера"
          isDismissible={false}
        >
          {usersCall.error?.message || subscriptionsCall.error?.message}
        </Alert>
      </View>
    );
  }

  if (usersCall.loading || subscriptionsCall.loading) {
    return (
      <View>
        <Loader
          size="small"
          variation="linear"
          ariaLabel="Загрузка видео ..."
        />
      </View>
    );
  }

  return (
    <View>
      <Heading level={"2"}>Пользователи</Heading>
      <Divider marginTop="20px" marginBottom="20px" />
      <Grid
        templateColumns={{
          base: "1fr",
          medium: "1fr 1fr 1fr",
          large: "1fr 1fr 1fr 1fr",
        }}
        columnGap="0.5rem"
        rowGap="0.5rem"
      >
        {users &&
          users.map((user) => (
            <UserRow
              key={user.Username}
              user={user}
              subscription={subscriptions.find(
                (subscription) => user.Username === subscription.userId
              )}
              onAddDays={handleAddDays}
              onAddViews={handleAddViews}
            />
          ))}
      </Grid>
    </View>
  );
};

export default Users;
