import React, { useState, useEffect } from "react";
import {
  View,
  Loader,
  Card,
  Alert,
  Heading,
  Flex,
  Divider,
  Text,
  Button,
} from "@aws-amplify/ui-react";

import { useParams } from "react-router-dom";

import { getVideo as getPrivateVideo } from "../api/private";
import { getVideo as getPublicVideo } from "../api/public";
import { useAppContext } from "../components/AppStore";
import dateToString from "../lib/dateToString";
import { buyVideo } from "../api/private";

const canWatchVideo = ({ video, subscription, views }) => {
  if ( subscription && subscription.expiresAt && Date.parse(subscription.expiresAt) > new Date() ) return true;
  if ( views && views.find((view) => video.id === view.videoId) ) return true;

  return false;
};

const canBuyVideo = ({ video, subscription, views }) => {
  return (
    !canWatchVideo({ video, subscription, views }) && subscription?.views > 0
  );
};

const Watch = () => {
  const { id } = useParams();
  const [video, setVideo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentUser, views, subscription, setViews, setSubscription } = useAppContext();
  const view = views?.find((view) => view.videoId === id);

  const fetchVideo = async () => {
    try {
      const response = await (currentUser === undefined ? getPublicVideo : getPrivateVideo)(id);
      if (response.data) {
        setVideo(response.data);
      } else {
        setError({
          heading: "Video not found!",
          message:
            "Video you are trying to open not found! Please go to home page and list available videos.",
        });
      }

      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  const handleBuy = async (id) => {
    const { data } = await buyVideo(id);
    setViews([...views, data]);
    setSubscription({ ...subscription, views: +subscription.views - 1 });
    await fetchVideo(id);
  };

  useEffect(() => { fetchVideo() }, [id, currentUser]);

  if (error) {
    return (
      <View>
        <Alert
          variation="error"
          hasIcon={true}
          heading={error.heading || "Server error"}
          isDismissible={false}
        >
          {error.message}
        </Alert>
      </View>
    );
  }

  if (loading) {
    return (
      <View>
        <Loader size="small" variation="linear" ariaLabel="Loading video ..." />
      </View>
    );
  }

  return (
    <View height="100%" width="100%">
      <Card height="100%" width="100%">
        <Heading level={"5"}>{video.title}</Heading>
        <Divider marginTop="20px" marginBottom="20px" />
        <Text>{video.description}</Text>
        <Divider marginTop="20px" marginBottom="20px" />
        <Text>
          {Object.entries(
            (video.tags || []).reduce((res, tag) => {
              res[tag.name] = [...(res[tag.name] || []), tag.value];
              return res;
            }, {})
          ).map((entry) => {
            const [key, value] = entry;

            return key + ": " + value.join(", ") + "; ";
          })}
        </Text>
        <Divider marginTop="20px" marginBottom="20px" />
        {view?.expiresAt && Date.parse(view.expiresAt) > new Date() && (
          <Text fontWeight="bold">
            Видео доступно еще {dateToString(view.expiresAt)}
          </Text>
        )}
        {subscription?.expiresAt &&
          Date.parse(subscription.expiresAt) > new Date() && (
            <Text fontWeight="bold">
              Видео доступно еще {dateToString(subscription.expiresAt)}
            </Text>
          )}
        <Divider marginTop="20px" marginBottom="20px" />
        {video.vimeoUrl && canWatchVideo({ video, subscription, views }) ? (
          <Flex style={{ aspectRatio: "3/2" }}>
            <iframe
              title="Watch Video"
              height="100%"
              width="100%"
              style={{ border: "none" }}
              src={video.vimeoUrl}
              allow="autoplay; fullscreen"
              allowFullScreen
            ></iframe>
          </Flex>
        ) : canBuyVideo({ video, subscription, views }) ? (
          <Button variation="destructive" onClick={() => handleBuy(video.id)}>
            Активизировать видео
          </Button>
        ) : currentUser === undefined ? (
          <Text variation="error">
            Авторизуйтесь, чтобы получить доступ к видео.
          </Text>
        ) : (
          <Text variation="error">
            Купите абонемент или просмотр, чтобы активизировать это видео
          </Text>
        )}
      </Card>
    </View>
  );
};

export default Watch;
