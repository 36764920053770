import { API } from "aws-amplify";

const RENTALS_VIEWER_API = "PrivateApi";

const callEndpoint = async (method, endpoint, params = {}) => {
  try {
    return await API[method](RENTALS_VIEWER_API, endpoint, params);
  } catch (error) {
    console.info(error);

    throw error;
  }
};

const getVideo = async (id) => {
  return await callEndpoint("get", `/videos/${id}`);
};

const buyVideo = async (id) => {
  return await callEndpoint("get", `/videos/${id}/buy`);
};

const listViews = async (params) => {
  return await callEndpoint("get", "/views", { queryStringParameters: params });
};

const getSubscription = async (userId) => {
  return await callEndpoint("get", "/subscriptions", {
    queryStringParameters: { userId },
  });
};

export { listViews, getVideo, getSubscription, buyVideo };
